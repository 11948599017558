import {Card} from "./Card";
import Field from "./Field";
import {Row} from "./Field";
import React from "react";
import {log} from "node:util";

export default class Fantasy {
    // static findBest(cards: Card[]) {
    //     let indexes = new Array(14).fill(0).map((_, index) => index);
    //     let i = 0;
    //     let maxScore: number = 0;
    //     let bestField: Row[] = [];
    //     let combinations = Fantasy.combinations(indexes, 3);
    //     let rows: number[][] = [[], [], []];
    //     combinations.forEach(combination => {
    //         rows[0] = combination;
    //         let indexesLeft = indexes.filter(index => combination.indexOf(index) === -1);
    //         let combinations2 = Fantasy.combinations(indexesLeft, 5);
    //         combinations2.forEach(combination => {
    //             rows[1] = combination;
    //             let indexesLeft2 = indexes.filter(index => rows[0].concat(rows[1]).indexOf(index) === -1);
    //             indexesLeft2.forEach(index => {
    //                 i++;
    //                 rows[2] = indexesLeft2.filter(i => i !== index);
    //                 let field = new Field(rows.map(row => new Row(row.map(index => cards[index]))));
    //                 if (field.score > maxScore) {
    //                     maxScore = field.score;
    //                     bestField = field.rows;
    //                 }
    //             });
    //         });
    //     });
    //     console.log({i, maxScore, bestField});
    // }

    /*
    * TODO:
    1) При равном кол-ве очков не выбирается наилучшая комбинация по старшенству
    2) Если одна комбинация приводит ко второй фантазии, а другая на одно очко лучше, то предложится она,
       хотя лучше бы получить еще одну фантазию


    * */
    static findBestWorkers(cards: Card[], callback: Function) {
        let indexes = new Array(14).fill(0).map((_, index) => index);
        let maxScore: number = 0;
        let bestField: string[] = [];
        let combinations: any[] = Fantasy.combinations(indexes, 3);
        let workersStarted = 0;
        let workersNumber = window.navigator.hardwareConcurrency;
        let workerResults: any[] = [];
        let totalIterations = 0;
        let timeStart = Date.now();
        for (let i = 0; i < workersNumber; ++i) {
            let worker = new Worker(new URL("./Worker/fantasyFindBestWorker.ts", import.meta.url));
            // let worker = new Worker('/fantasyFindBestWorker.js', { type: "module" });
            worker.onmessage = (e: any) => {
                let event: any = e.data;
                workerResults.push(event);
                if (event.maxScore > maxScore) {
                    maxScore = event.maxScore;
                    bestField = event.bestField;
                }
                totalIterations += event.i;
                console.log('workersDone', workerResults.length, workersStarted);
                if (workerResults.length === workersStarted) {
                    workerResults.forEach(result => {
                        if (result.maxScore > maxScore) {
                            maxScore = result.maxScore;
                            bestField = result.bestField;
                        }
                    })
                    callback(maxScore, bestField, Date.now() - timeStart, totalIterations);
                }
            }
            let from = Math.floor(combinations.length / workersNumber * i);
            let to = Math.floor(combinations.length / workersNumber * (i + 1));
            console.log({from, to, total: combinations.length});
            worker.postMessage({
                cards,
                indexes,
                combinations: combinations.slice(from, to)
            });
            workersStarted++;
            // throw 'worker started';
        }
    }

    static combinations(array: any[], size: number): any[] {
        const length = array.length;
        if (size > length) return [];
        if (!size) return [[]];
        if (size === length) return [array];
        return array.reduce(function (acc, val, i) {
            var res: any[] = Fantasy.combinations(array.slice(i + 1), size - 1)
                .map(function (comb: any[]) {
                    return [val].concat(comb);
                });
            return acc.concat(res);
        }, []);
    }
}
