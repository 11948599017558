export class Card {
    static cards = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
    digit: string;
    suit: string;
    suitInfo: Record<string, any>;
    chosen: boolean;

    constructor(rankOrTitle: string, suit: string = '') {
        if (suit) {
            this.digit = rankOrTitle;
            this.suit = suit;
        } else {
            this.digit = rankOrTitle.slice(0, -1)
            this.suit = rankOrTitle.slice(-1)
        }
        this.chosen = false;
        this.suitInfo = Card.suits[this.suit];
    }

    static createArray(cardStrings: string[]) {
        return cardStrings.map(cardString => new Card(cardString));
    }

    static totalNumberOfCards() {
        return Card.cards.length * (Object.keys(Card.suits).length);
    }

    static suits: Record<string, any> = {
        h: {char: '♥', color: 'red', short: 'h'},
        s: {char: '♠', color: 'black', short: 's'},
        d: {char: '♦', color: '#0069d9', short: 'd'},
        c: {char: '♣', color: '#008a00', short: 'c'},
    };

}

// enum Suits2 {
//     Spades = '♠',
//     Hearts = '♥',
//     Clubs = '♣',
//     Diamonds = '♦'
// }
//


