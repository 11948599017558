import React from 'react';
import '../../App.css';
import Player from "./Player";

let mounted = false;
export default class Main extends React.Component {
    state: Record<any, any> = {
        // players: [
        //     {
        //         "rows": [
        //             [
        //                 "Qh",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "Jd",
        //                 "",
        //                 "",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "Kd",
        //                 "Kh",
        //                 "9s",
        //                 "",
        //                 ""
        //             ]
        //         ],
        //         "turn": [
        //             "6c",
        //             "9c",
        //             "4h"
        //         ]
        //     },
        //     {
        //         "rows": [
        //             [
        //                 "2s",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "5d",
        //                 "",
        //                 "",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "Ts",
        //                 "8s",
        //                 "4s",
        //                 "",
        //                 ""
        //             ]
        //         ],
        //         "turn": []
        //     },
        //     {
        //         "rows": [
        //             [
        //                 "",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "9d",
        //                 "9h",
        //                 "",
        //                 "",
        //                 ""
        //             ],
        //             [
        //                 "Ac",
        //                 "Ks",
        //                 "Td",
        //                 "",
        //                 ""
        //             ]
        //         ],
        //         "turn": []
        //     }
        // ],
        players: [],
    };

    componentDidMount() {
        if (mounted) return;
        mounted = true;
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.type === 'players') {
                this.setState({players: event.data.players});
                console.log('got players', event.data.players);
            }
        });
        window.postMessage('readyForPlayers', '*');
        console.log('dispatchEvent', 'readyForPlayers');
        // setInterval(() => {
        //     let players = this.state.players;
        //     let value = players[0].rows[0][0];
        //     players[0].rows[0][0] = value === '6s' ? 'Qh' : '6s';
        //     console.log('changed to ' + players[0].rows[0][0]);
        //     this.setState({players});
        // }, 1000);
    }

    render() {
        return (
            <div className="GameField">
                <div className="stats"></div>
                <div className="players">
                    {(this.state.players[1]) ? <Player player={this.state.players[1]}/> : ''}
                    {(this.state.players[0]) ? <Player player={this.state.players[0]}/> : ''}
                    {(this.state.players[2]) ? <Player {...{player: this.state.players[2]}}/> : ''}
                </div>
            </div>
        )
    }
}
