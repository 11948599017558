import React from 'react';
import './App.css';
import './style.scss'
import Main from "./Views/Game/Main";
import {Link, Route, Routes} from "react-router-dom";
import Fantasy from "./Views/Fantasy";

export default class App extends React.Component {
    render() {
        return (<div className="App">
            <header className="App-header">
                <p>AN</p>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/fantasy">Fantasy</Link></li>
                </ul>
            </header>
            <main className="An-main">
            <Routes>
                <Route index element={<Main />} />
                <Route path="/fantasy" element={<Fantasy />} />
            </Routes>

            </main>
        </div>)
    }
}
