import React from 'react';
import CardView from "../Card";
import {Card as CardModel} from "../../Ananas/Card";

interface PlayerProps extends React.PropsWithRef<any> {
   player: any
}
export default class Player extends React.Component<PlayerProps, any> {
    render() {
        return (
            <div className="player">
                <div className="turn">
                    {this.props.player.turn.map((cardTitle: string, i: number) => <CardView key={i+cardTitle} {...{card: new CardModel(cardTitle)}}/>)}
                </div>
                <div className="rows">
                    {this.props.player.rows.map((row: [], i: number) =>
                        <div key={i} className="row">
                            {row.map((cardTitle: string, i: number) => <CardView key={i+cardTitle} {...{card: new CardModel(cardTitle)}}/>)}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
