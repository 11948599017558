import React from 'react';

export default class CardView extends React.Component {
    state: Record<any, any> = {
        card: {}
    };

    constructor(props: any) {
        super(props);
        this.state.card = props.card;
    }

    render() {
        return (
            this.state.card.digit ?
                <div
                    className={'An-card'}
                    style={{color: this.state.card.suitInfo.color}}>
                    {this.state.card.digit}{this.state.card.suitInfo.char}
                </div> : <div className={'An-card'}></div>
        )
    }
}
