import React from 'react';
import '../App.css';
import {Card} from "../Ananas/Card";
import {default as FantasyCalc} from "../Ananas/Fantasy";
import {Row} from "../Ananas/Field";

function shuffleArray(array: Array<any>) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export default class Fantasy extends React.Component {
    constructor(props: any) {
        super(props);
        let cards: Card[] = [];
        Card.cards.forEach(value => {
            Object.keys(Card.suits).forEach((suit: string) => {
                cards.push(new Card(value, suit))
            })
        })
        this.state.cards = cards;
    }

    state: Record<any, any> = {
        cards: [],

        bestField: [],
        maxScore: null,
        calculating: false,
    };

    onCardClick = (card: Card) => {
        card.chosen = !card.chosen;
        this.setState({cards: this.state.cards});
    }

    onCalculate = () => {
        let cards = this.state.cards.filter((card: Card) => card.chosen);
        shuffleArray(cards);
        this.setState({calculating: true})
        FantasyCalc.findBestWorkers(cards, (maxScore: number, bestField: string[], time: number, totalIterations: number) => {
            this.setState({bestField, maxScore, calculating: false, calculationTime: time, totalIterations});
        });
    }

    render() {
        return (<div>
            <div className="An-cards">
                {Object.keys(Card.suits).map(suit =>
                    <div className="An-card-column" key={suit}>
                        {this.state.cards.filter((card: Card) => card.suit === suit).map((card: Card) =>
                            <div onClick={() => this.onCardClick(card)}
                                 className={'An-card' + (card.chosen ? ' active' : '')}
                                 style={{color: card.suitInfo.color}}
                                 key={card.digit + card.suit}>{card.digit}{card.suitInfo.char}</div>
                        )}
                    </div>
                )}
            </div>
            <div>
                Chosen: {this.state.cards.filter((card: Card) => card.chosen).length}/14 cards
                <div>
                    {this.state.cards.filter((card: Card) => card.chosen).map((card: Card) =>
                        <span key={card.digit + card.suit} style={{
                            color: card.suitInfo.color,
                            marginRight: '5px'
                        }}>{card.digit}{card.suitInfo.char}</span>
                    )}
                </div>
            </div>
            <button style={{marginTop: '20px'}}
                    disabled={this.state.calculating || this.state.cards.filter((card: Card) => card.chosen).length !== 14}
                    onClick={() => this.onCalculate()}>{this.state.calculating ? 'Calculating...' : 'Calculate best field'}
            </button>
            {this.state.bestField.length ?
                <div>
                    {this.state.bestField.map((row: Row, index: number) => <div key={index}>{row.cards.map(card =>
                        <span key={card.digit + card.suit} style={{
                            color: card.suitInfo.color,
                            marginRight: '5px'
                        }}>{card.digit}{card.suitInfo.char}</span>
                    )}, score {row.score}</div>)}
                    <div>Total score: {this.state.maxScore},
                        time: {this.state.calculationTime} ms, {this.state.totalIterations} iterations
                    </div>
                </div>
                : ''}
        </div>)
    }
}
